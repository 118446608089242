import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_22 from '../../images/leistungen/img22.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="PRP Eigenbluttherapie | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet PRP Eigenbluttherapien mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"PRP Eigenbluttherapie"} subtitle={"Selbstheilungskräfte des Körpers"} frontImage={img_leistung_22}></HeadlineWithImage>
          </Col>
        </Row>

        PRP steht für Platelet-Rich-Plasma bzw. plättchenreiches Plasma.
        <br/>
        <br/>
        Es wird Ihnen eine kleine Menge Blut abgenommen, das durch ein spezielles Trennverfahren sicher und steril in einer Zentrifuge separiert wird. Das daraus gewonnene Blutplasma ist reich an Blutplättchen (Thrombozyten) und verschiedenen Wachstumsfaktoren. Diese wirken geweberegenerativ. Das gewonnene Plasma stimuliert den Körper, ähnlich wie bei der Wundheilung, an der erkrankten Stelle neue Zellen (Blutgefäße, Hyaluronsäure, Kollagen, Elastin) entstehen zu lassen. 
        <br/>
        <br/>
        Die zellerneuernde und wundheilende Wirkung von PRP findet sowohl in der ästhetischen Medizin als auch Orthopädie seine Anwendung. 
        <br/>
        <br/>
        Der Vorteil gegenüber allen anderen Therapien ist, dass nur Ihr eigenes Blut als Heilmittel fungiert, ohne Zusatz. Allergien oder Unverträglichkeitsreaktionen sind ausgeschlossen. 
        <br/>
        <br/>
        <SimpleModernHeadline h3={true}>Wann kommt PRP zum Einsatz?</SimpleModernHeadline>
        <ul>
          <li className="noMargin">Sportverletzungen:  Verkürzt die Regenerationszeit/ im Profisport seit Jahren bewährt</li>
          <ul>
            <li className="noMargin">Weitere Informationen finden Sie auf der Seite der <a className="whiteLink" href="https://www.schubert-bochum.de/">Praxis für Orthopädie</a></li>
          </ul>
          <li className="noMargin">Arthrosetherapie: Gelenkinjektion, auch in Kombination mit Hyaluron</li>
          <ul>
            <li className="noMargin sublistPadding">Weitere Informationen finden Sie auf der Seite der <a className="whiteLink" href="https://www.schubert-bochum.de/therapien/klassisch">Praxis für Orthopädie</a></li>
          </ul>
          <li className="noMargin">Sehnenentzündungen</li>
          <li className="noMargin">Schlecht heilende Wunden</li>
          <li className="noMargin">Frakturbehandlungen</li>
          <li className="noMargin">Ästhetische Medizin</li>
        </ul>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
